.home-container {
  background: url("../../assets/images/home-page-banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 50px;
  background-position: center center;

  .charity-search {
    border: 2px solid #767675;
    padding: 20px;
    background: #ffffff;

    .charity__placeholder {
      color: #2f527a;
    }

    .select-location {
      margin: 20px 0px;
    }

    .charity__dropdown-indicator {
      color: #2f527a;
    }

    h1 {
      color: #2f527a;
      font-size: 22px;
      font-weight: 600;
    }

    .search-button {
      background: #2f527a;
      border: 1px solid #2f527a;
      color: #ffffff;
      width: 100%;
      height: 40px;
      margin-top: 10px;
      padding: 7px;
      outline: none;
      border-radius: 16px;

      &:hover {
        background: #294769;
        color: #ffffff;
      }
    }
  }
}

.more-options-button {
  color: #2f527a;
  background: #ffffff;
  border: 1px solid #ffffff;
  font-weight: 600;
}

.charity-search-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  p {
    color: #2f527a;
    margin: 0;
    font-weight: 600;
  }
}

.hr-home {
  border-top: 4px solid #2f527a !important;
  width: 86%;
}

.hr-homebottom {
  border-top: 4px solid #2f527a !important;
  width: 94%;
}

.mini-logo-container {
  text-align: center;
  padding-bottom: 20px;
}

.second-home-container {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0px 50px;
  background-position: center center;

  h4 {
    color: #2f527a;
    font-size: 24px;
    font-weight: 300;
  }
}

.error-text {
  text-align: center;
  font-size: 16px;
  color: #FF0000;
  padding-top: 20px;
}