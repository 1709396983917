.navbar-container {
  .nav-link {
    color: #0b4688 !important;
    text-transform: uppercase;
    font-weight: normal;
    border-right: 2px solid #0b4688;
    padding: 0;
    font-size: 14px;
    font-weight: 600;

    &:last-child {
      border: none;
    }

    @media (max-width: 767px) {
      border: none;
      padding-bottom: 15px;
      font-weight: 600;
      text-align: center;
    }
  }

  .navbar-light .navbar-toggler {
    background: #ffffff;
  }
}

.navbar-search-btn {
  @media (max-width: 767px) {
    text-align: center;
  }

  button {
    background: #083971;
    border: 1px solid #083971;
    padding: 5px 15px;
    outline: none;

    @media (max-width: 767px) {
      width: 50px;
    }
  }
}

.navbar-brand img {
  @media (max-width: 767px) {
    width: 200px;
  }
}
