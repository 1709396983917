.charity-detail-content {
  border: 2px solid #767676;
  margin-top: 30px;
  margin-bottom: 30px;

  h2 {
    color: #0b4688;
    font-weight: 600;
    font-size: 24px;
    padding-bottom: 10px;
  }

  h3 {
    color: #0b4688;
    font-weight: 600;
    font-size: 20px;
  }

  h4 {
    color: #0b4688;
    font-weight: 300;
    font-size: 20px;
    a {
      font-size: 13px;
    }
  }

  h5 {
    color: #0b4688;
    font-weight: 600;
    text-align: center;
    font-size: 20px;
  }

  h5 {
    color: #0b4688;
    font-weight: 600;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;

  }

  h6 {
    color: #239dc8;
    font-weight: 600;
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 10px;
    a {
      font-size: 16px;
    }
    p {
      display: inline;
      font-size: 16px;
    }
  }

  p {
    color: #0b4688;
    font-size: 16px;
    padding-bottom: 10px;
  }

  b { 
    font-weight: bold; 
  }

  .charity-detail {
    display: grid;

    @media (max-width: 767px) {
      border: none;
    }
  }

  .charity-logo {
    border-right: 2px solid #767676;

    @media (max-width: 767px) {
      border: none;
    }
  }

  .read-more {
    text-align: right;
    margin-top: 30px;

    a {
      background: #0b4688;
      border: 1px solid #0b4688;
      color: #ffffff;
      font-weight: 600;
      outline: none !important;
      font-size: 16px;
      padding: 8px;
      margin: 0 auto;

      &:hover {
        background: #ffffff;
        color: #0b4688;
      }
    }
  }

  .charity-more-details {
    padding: 20px;
  }
}

.hr-detail {
  border-top: 4px solid #2f527a !important;
  width: 86%;
}

.detailed-grouping {
  padding: 15px;
}

.fieldset-detailed {
  border: 2px solid #cccccc;
  padding: 15px;
  border-radius: 15px;
  a {
    font-size: 16px;
  }
  p {
    font-size: 16px;
  }
}