.search-result-content {
  background: #e6eafc;
  padding: 20px;
  margin: 30px 0px;

}

.sort-order {
  margin: 20px 0px;
  width: 50%;
  text-align: left;
  float: right;
}

.result {
  background: #ffffff;
  display: grid;
  border: 2px solid #767676;
  margin-top: 10px;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    border: 2px solid #767676;
  }

  h2 {
    color: #2f527a;
  }

  h3 {
    color: #2f527a;
    font-weight: 600;
  }

  h5 {
    color: #2f527a;
    font-weight: 600;
    font-size: 24px;
  }

  h6 {
    color: #239dc8;
    font-weight: 600;
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 10px;
    a {
      font-size: 18px;
    }
    p {
      display: inline;
      font-size: 16px;
    }
  }

  p {
    color: #2f527a;
    font-size: 16px;
    font-weight: 300;
    padding-bottom: 10px;
    a {
      font-size: 13px;
    }
  }

  b { 
    font-weight: bold; 
  }
}

.charity-logo {
  border-right: 2px solid #767676;
  padding: 20px;

  @media (max-width: 767px) {
    border: none;
  }
}

.charity-details {
  padding: 20px;
  font-weight: 600;

  @media (max-width: 767px) {
    border: none;
  }
}

.read-more {
  text-align: right;
  margin-top: 30px;

  a {
    background: #2f527a;
    border: 1px solid #2f527a;
    color: #ffffff;
    font-weight: 600;
    outline: none !important;
    font-size: 14px;
    padding: 8px;
    margin: 0 auto;

    &:hover {
      background: #ffffff;
      color: #294769;
    }
  }
}

.hr-search {
  border-top: 4px solid #2f527a !important;
  width: 86%;
}

.details-btn {
  text-align: center;

  button {
    background: #2f527a;
    border: 1px solid #2f527a;
    color: #ffffff;
    font-weight: 600;
    outline: none !important;
    font-size: 14px;
    padding: 8px;
    width: 25%;
    height: 40px;
    border-radius: 16px;

    &:hover {
      background: #294769;
      color: #ffffff;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 30px;
    }
  }
}

.text-secondary {
  color: #2f527a;
}

.h2covert {
  color: #2f527a;
  font-size: 32px;
}

.pchange {
  color: #239dc8;
  font-size: 16px;
}

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

.loadingimage {
  padding-top: 100px;
  text-align: center;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

$primary-color: #00005c; // Change color here. C'mon, try it! 
$text-color: mix(#000, $primary-color, 64%);

.body-choice {
  font-family: "Inter", sans-serif;
  color: $text-color;
  font-size: calc(1em + 1.25vw);
  background-color: mix(#fff, $primary-color, 90%);
}

.form-choice {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.label-choice {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
  /* Accessible outline */
  /* Remove comment to use */ 
  /*
    &:focus-within {
        outline: .125em solid $primary-color;
    }
  */
  input {
    position: absolute;
    left: -9999px;
    &:checked + span {
      background-color: mix(#fff, $primary-color, 84%);
      &:before {
        box-shadow: inset 0 0 0 0.4375em $primary-color;
      }
    }
  }
  span {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em; // or something higher...
    transition: 0.25s ease;
    &:hover {
      background-color: mix(#fff, $primary-color, 84%);
    }
    &:before {
      display: flex;
      flex-shrink: 0;
      content: "";
      background-color: #fff;
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      margin-right: 0.375em;
      transition: 0.25s ease;
      box-shadow: inset 0 0 0 0.125em $primary-color;
    }
  }
}