.latest-news-wrapper {
  background: #e6eafc;
  padding: 20px;

  h2 {
    color: #2f527a;
    font-weight: 600;
    font-size: 32px;
  }

  .news-articles {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-top: 20px;
    grid-gap: 20px;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }

    .article {
      h5 {
        color: #2f527a;
        font-weight: 600;
        padding-top: 15px;
        font-size: 20px;
      }

      p {
        color: #332724;
        font-size: 16px;
      }
    }
  }
}