.advance-search-wrapper {
  .title {
    text-align: center;

    h1 {
      color: #2f527a;
      font-weight: 600;
      font-size: 36px;
    }
    h2 {
      color: #2f527a;
      font-weight: 300;
      font-size: 30px;
      white-space: nowrap;
    }
  }
}
.advance-search-content {
  border: 2px solid #2f527a;
}

.advance-search-fields {
  padding-top: 30px;

  .charity__placeholder {
    color: #0b4688;
  }

  .charity__dropdown-indicator {
    color: #0b4688;
  }
}

.advance-search-btn {
  button {
    background: #2f527a;
    border: 1px solid#2f527a;
    color: #ffffff;
    font-weight: 600;
    outline: none !important;
    font-size: 14px;
    padding: 8px;
    height: 40px;
    width: 50%;
    border-radius: 16px;

    &:hover {
      background: #294769;
      color: #ffffff;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 30px;
    }
  }
}

.search-btn {
  text-align: center;

  button {
    background: #0b4688;
    border: 1px solid #0b4688;
    color: #ffffff;
    font-weight: 600;
    outline: none !important;
    font-size: 14px;
    padding: 8px;
    height: 40px;
    width: 50%;
    border-radius: 16px;

    &:hover {
      background: #062e5c;
      color: #ffffff;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 30px;
    }
  }
}

.select-textentry {
  margin-top: 15px;
  margin-bottom: 30px;
  height: 35px;
  color: #000000;
  border-radius: 4px;
  border-color: #8f3131;
}

.textentry {
  margin-bottom: 30px;
  width: 100%;
  color: #0b4688;
  border-radius: 3px;
  padding: 10px;
  height: 40px;
  border-color: #cccccc;
}

.select-dropdown {
  margin-bottom: 30px;
  margin-top: 0px;
  width: 100%;
}

.select-info-dropdown {
  margin-bottom: 6px;
  margin-top: 0px;
  width: 100%;
}

.advance-search-content {
  border: 2px solid #767674;
  padding: 30px;
  background: #ffffff;

  @media (max-width: 767px) {
    margin: 50px 0px;
  }
}

.volunteers {
  padding: 20px;
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}

.hr-adv {
  border-top: 4px solid #2f527a !important;
  width: 86%;
}

.hr-advbottom {
  border-top: 4px solid #2f527a !important;
  width: 71%;
}

.green-bg {
  background: #e6eafc;
  padding: 20px;
}

.fieldset-adv {
  border: 2px solid #cccccc;
  padding: 15px;
  border-radius: 15px;
}

.top-buffer { margin-top:20px; }

.simpleparagraph {
  font-size: 16px;
  color: #2f527a;
}

.error-text {
  font-size: 16px;
  color: #FF0000;
}

.paragraphspacer {
  font-size: 16px;
  color: #2f527a;
  padding-bottom: 10px;
}

.radioformat {
  color: #2f527a;
  padding-bottom: 10px;
  border: "10px solid #2f527a"
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

$primary-color: #00005c; // Change color here. C'mon, try it! 
$text-color: mix(#000, $primary-color, 64%);

.body-choice {
  font-family: "Inter", sans-serif;
  color: $text-color;
  font-size: calc(1em + 1.25vw);
  background-color: mix(#fff, $primary-color, 90%);
}

.form-choice {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.label-choice {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
  /* Accessible outline */
  /* Remove comment to use */ 
  /*
    &:focus-within {
        outline: .125em solid $primary-color;
    }
  */
  input {
    position: absolute;
    left: -9999px;
    &:checked + span {
      background-color: mix(#fff, $primary-color, 84%);
      &:before {
        box-shadow: inset 0 0 0 0.4375em $primary-color;
      }
    }
  }
  span {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em; // or something higher...
    transition: 0.25s ease;
    &:hover {
      background-color: mix(#fff, $primary-color, 84%);
    }
    &:before {
      display: flex;
      flex-shrink: 0;
      content: "";
      background-color: #fff;
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      margin-right: 0.375em;
      transition: 0.25s ease;
      box-shadow: inset 0 0 0 0.125em $primary-color;
    }
  }
}