.footer-container {

  .footer-link {
    
    background: #2f527a;
    font-weight: normal;
    border-right: 2px solid #45658a;
    padding: 20px;
    padding-left: 40px;
    padding-top: 40px;
    font-size: 14px;
    font-weight: 300;
    color: #ffffff;

    h5 {
      color: #ffffff;
      font-size: 20px;
      font-weight: 300;
    }

    h6 {
      color: #ffffff;
      font-size: 16px;
      font-weight: 300;
    }

    p {
      color: #ffffff;
      font-size: 12px;
      font-weight: 300;
    }

    a {
      color: #ffffff;
      font-weight: 300;
      font-size: 16px;
    }

    &:last-child {
      border: none;
    }

    @media (max-width: 767px) {
      border: none;
      padding-bottom: 15px;
      font-weight: 600;
      text-align: center;
    }
  }
}

.mail-part {
  border-top: 20px solid #2f527a !important;
}

.mailtextentry {
  margin-bottom: 10px;
  width: 100%;
  color: #0b4688;
  border-radius: 0px;
  padding: 6px;
  height: 35px;
  border-color: #ffffff;
}

.mail-button {
  color: #2f527a;
  background: #e6eafc;
  border: 1px solid #e6eafc;
  font-weight: 600;
  font-size: 16px;
  height: 32px;
  width: 100px;
  margin-top: 15px;
}

.footer-list {
    padding-bottom: 5px;
}

