.news-and-features-content {
  background: #e6eafc;
  padding: 50px;
  margin: 30px 0px;

  h1 {
    color: #2f527a;
    font-weight: 600;
  }

  p {
    color: #2f527a;
    font-size: 14px;
  }
}

.hr-news {
  border-top: 4px solid #2f527a !important;
  width: 86%;
}

.hr-newsbottom {
  border-top: 4px solid #2f527a !important;
  width: 71%;
}

.accordion {
  width: 75%;
  background-color: transparent;
  margin: auto;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 50px;
}

.accordion-title {
 height: 50px;
 width: 100%;
 background-color: #2f527a;
 color: #ffffff;
 text-transform: uppercase;
 letter-spacing: 1px;
 text-align: left;
 line-height: 2;
 font-weight: lighter;
 position: relative;
 padding: 10px;
 z-index: 2000;
 border-radius: 4px;
 margin-top: 2px;
 transition: all .2s ease-in;
}

.accordion-title-text {
 margin-left: 10px;
}

.accordion-title:hover {
 cursor: pointer;
 background-color: #294769;
}

.accordion-title:active {
 background-color: rgba(0, 0, 0, .55);
}

.accordion-content {
 height: 30px;
 width: 100%;
 background-color: #e6eafc;
 border-radius: 4px;
 color:#2f527a;
 font-size: 14px;
 text-align: center;
 position: relative;
 z-index: 1000;
 margin-top: -30px;
 text-align: left;
 transition: all 200ms cubic-bezier(0.600, -0.280, 0.735, 0.045);
}

.accordion-content-open {
 margin-top: 0px;
 height: 200px;
 background-color: rgba(0,0,0, .1);
 transition: all 350ms cubic-bezier(0.080, 1.090, 0.320, 1.275);
}

.accordion-content-text {
 padding: 15px;
 visibility: hidden;
 opacity: 0;
 overflow: auto;
 transition: all .2s ease-in;
}

.accordion-content-text-open {
 visibility: visible;
 opacity: 1;
 transition: all .8s ease-in;
}

.accordion-fa-angle-down {
 font-size: 20px;
 color: rgba(255,255,255, .5);
 transition: all .6s cubic-bezier(0.080, 1.090, 0.320, 1.275);
}

.accordion-fa-rotate-180 {
 color: rgba(255,255,255, 1);
}

.accordion-arrow-wrapper {
 position: absolute;
 margin-left: 375px;
}