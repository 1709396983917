.support-us-content {
  background: #e6eafc;
  padding: 50px;
  margin: 30px 0px;

  h1 {
    color: #2f527a;
    font-weight: 600;
    font-size: 36px;
  }

  h5 {
    color: #2f527a;
    font-weight: 600;
  }

  p {
    color: #2f527a;
    font-size: 18px;
  }

  b { 
    font-weight: bold; 
  }

  ul {
    padding: 10px;
    list-style-type: disc;
    list-style-position: inside;
    list-style-image: none;
  }
}

.hr-supportus {
  border-top: 4px solid #2f527a !important;
  width: 86%;
}
